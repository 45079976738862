<style>
    .country-info-window.gm-style-iw-c {
        max-width: 200px !important;
    }

    .country-info-window .gm-style-iw-d {
        position: relative;
        top: -27px;
    }

    .country-info-window button.gm-ui-hover-effect {
        right: -10px;
        top: -11px;
    }

    .image_section {
        flex: 0 0 200px;
        /* Set a fixed width for the image */
        margin-right: 20px;
        /* Space between the image and title */
        /* float: left; */
        border: 1px solid #ece8e8;
        border-radius: 20px;
        padding: 10px;
    }

    .image_section img {
        width: 100%;
        /* Ensure image fills its container */
        height: 200px;
        /* Fixed height for the image */
        object-fit: cover;
        /* Ensure image is cropped and scaled proportionally */
    }

    .image_title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .image_title h5 {
        margin: 0;
    }

    .image_title .book-button {
        margin-top: 10px;
    }

    .image_title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        max-width: 350px;
        transition: box-shadow 0.3s ease;
    }

    .image_title h5 {
        margin: 0;
        font-size: 1.25rem;
        font-weight: bold;
    }

    .image_title a {
        font-size: 16px;
        line-height: 30px;
    }

    .address,
    .property-id,
    .property-type {
        font-size: 0.9rem;
        color: #777;
        margin-bottom: 8px;
    }

    .address {
        font-weight: 500;
    }

    .property-id,
    .property-type {
        font-style: italic;
    }

    .rating-and-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
    }

    .rating {
        display: flex;
        align-items: center;
    }

    .book-now {
        display: flex;
        justify-content: center;
    }

    .book-button {
        background-color: #ff762b;
        color: white;
        padding: 8px 20px;
        border: none;
        border-radius: 25px;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .book-button:hover {
        background-color: #ff5722;
    }

    .image_title:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }

    /* This is a single-line comment */
    .property-card {
        display: flex;
        background-color: #ffffff;
        border-radius: 12px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        transition: box-shadow 0.3s ease;
        max-width: 600px;
        margin: 20px;
    }

    .property-card:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }

    .image-section {
        flex: 0 0 200px;
        overflow: hidden;
    }

    .image-section img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
    }

    .image-section img:hover {
        transform: scale(1.05);
    }

    .content-section {
        flex: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .content-section h5 {
        font-size: 1.5rem;
        font-weight: bold;
        color: #333;
        margin: 0 0 10px;
        text-align: center;
    }

    .content-section .address {
        font-size: 1rem;
        color: #555;
        font-weight: 500;
        text-align: center;
        margin-bottom: 10px;
    }

    .property-details {
        font-size: 0.9rem;
        color: #777;
        font-style: italic;
        margin-bottom: 20px;
        text-align: center;
    }

    .rating-and-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .book-button {
        background-color: #ff762b;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 25px;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;
        box-shadow: 0 4px 10px rgba(255, 118, 43, 0.3);
    }

    .book-button:hover {
        background-color: #ff5722;
    }

    .rating {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        color: #333;
    }

    .property-card h5 a {
        text-decoration: none;
        color: inherit;
    }

    .property-card h5 a:hover {
        color: #ff762b;
    }

    /* This is a single-line comment end  */
</style>

<template>
    <div class="my-5 container">
        <div class="text-main">
            <h2 class="font-weight-bolder">{{ $t("header.map") }}</h2>
        </div>
        <div class="mt-5 map-container">
            <l-map ref="map" id="map" style="width: 100%; height: 600px" :zoom="1" @ready="initializeMap"
                @zoomend="onZoomChange" @moveend="onMapMove">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-marker v-for="(marker, index) in markers" :key="index" :lat-lng="marker.position"
                    @popupopen="onPopupOpen" @popupclose="onPopupClose">
                    <l-popup :options="{ permanent: true, interactive: true, autoClose: false,closeOnClick: false }">
                        <div v-html="marker.popupText"></div>
                    </l-popup>
                </l-marker>
            </l-map>


        </div>
    </div>
</template>

<script>
    import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
    import 'leaflet/dist/leaflet.css';
    import markerIcon from 'leaflet/dist/images/marker-icon.png';
    import markerShadow from 'leaflet/dist/images/marker-shadow.png';
    import L from 'leaflet';
    import { mapState, mapActions } from "vuex";

    // Fix default Leaflet marker icons
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconUrl: markerIcon,
        shadowUrl: markerShadow,
    });

    export default {
        components: {
            LMap,
            LTileLayer,
            LMarker,
            LPopup,
            // LTooltip
        },
        computed: {
            ...mapState("property", ["properties", "fetching"]),
        },
        data() {
            return {
                searchQuery: "",
                searchResults: [],  // Stores search results
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                zoom: 1,
                // center: [0, 0], // Default: London
                locationName: "",
                propertiesCountData: {},
                markers: [],
                isPopupOpen: false
            };
        },
        mounted() {
            window.vueApp = this;
            const map = this.$refs.map.mapObject;
            // this.fitMapToBoundingBox({
            //     "boundingbox": [
            //         "18.9916479",
            //         "19.2694771",
            //         "72.7763330",
            //         "72.9817485"
            //     ]
            // })
            map.setMaxBounds([
                [-90, -180], // Southwest corner
                [90, 180]    // Northeast corner
            ]);
        },

        methods: {
            ...mapActions("property", ["searchProperties", "countProperties"]),
            async initializeMap() {
                const response = await this.countProperties();
                const { data } = response.data;
                this.propertiesCountData = data;
                this.onZoomChange();

            },
            onPopupOpen() {
                console.log("📌 Popup Opened - Disabling move event");
                this.isPopupOpen = true;
            },
            onPopupClose() {
                console.log("✅ Popup Closed - Enabling move event");
                this.isPopupOpen = false;
                this.onMapMove();
            },
            fitMapToBoundingBox(place) {
                if (place.boundingbox) {
                    const bounds = [
                        [parseFloat(place.boundingbox[0]), parseFloat(place.boundingbox[2])], // SW (Southwest corner)
                        [parseFloat(place.boundingbox[1]), parseFloat(place.boundingbox[3])]  // NE (Northeast corner)
                    ];
                    this.$nextTick(() => {
                        this.$refs.map.mapObject.fitBounds(bounds);
                    });
                }
            },
            async fetchLocations() {
                if (!this.searchQuery) {
                    this.searchResults = [];
                    return;
                }

                const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(this.searchQuery)}`;

                try {
                    const response = await fetch(apiUrl);
                    const data = await response.json();
                    this.searchResults = data.slice(0, 5); // Show only the top 5 results
                } catch (error) {
                    console.error("Error fetching locations:", error);
                }
            },

            setWorldMarkers() {
                let formattedNumber = this.propertiesCountData.world.toLocaleString("en-IN");

                this.markers = [{
                    position: [0, 0],
                    popupText: `<div style="font-weight: 500;">
                                    You can sleep in ${formattedNumber} properties
                                </div>
                                <button style="
                                        border: none;
                                        margin-top: 10px;
                                        background: none;
                                        padding: 0;
                                        color: #0000de;
                                        text-decoration: underline;" onClick="window.vueApp.setZoomLevel(2)">
                                    View Continents
                                </button>`
                }];

                this.$nextTick(() => {
                    setTimeout(() => {
                        const map = this.$refs.map.mapObject;
                        map.eachLayer(layer => {
                            if (layer instanceof L.Marker && layer.getLatLng().lat === 0 && layer.getLatLng().lng === 0) {
                                layer.openPopup();
                            }
                        });
                    }, 100); // Small delay to ensure markers are added
                });
            },
            setContinentsMarkers() {
                this.markers = this.propertiesCountData.continent.map(continent => {
                    let formattedNumber = continent.noOfProperties.toLocaleString("en-IN");

                    return {
                        position: [continent.latitude, continent.longitude],
                        popupText: `
                                <div style="font-weight: 500;width: 200px;">
                                    You can sleep in ${formattedNumber} properties in ${continent.name}
                                </div>
                                <button style="
                                    border: none;
                                    margin-top: 10px;
                                    background: none;
                                    padding: 0;
                                    color: #0000de;
                                    text-decoration: underline;"
                                    onclick="window.vueApp.setZoomLevel(4, ${continent.latitude}, ${continent.longitude})">
                                    View ${continent.name}
                                </button>`
                    }
                });
                this.$nextTick(() => {
                    setTimeout(() => {
                        const map = this.$refs.map.mapObject;
                        this.markers.forEach(markerData => {
                            map.eachLayer(layer => {
                                if (layer instanceof L.Marker &&
                                    layer.getLatLng().lat === markerData.position[0] &&
                                    layer.getLatLng().lng === markerData.position[1]) {
                                    layer.openPopup();
                                }
                            });
                        });
                    }, 100); // Small delay to ensure markers are added
                });
            },
            setContriesMarkers() {
                this.markers = this.propertiesCountData.country.map(country => {
                    let formattedNumber = country.noOfProperties.toLocaleString("en-IN");
                    return {
                        position: [country.latitude, country.longitude],
                        popupText: `
                                <div style="font-weight: 500;">
                                    You can sleep in ${formattedNumber} properties in ${country.name}
                                </div>
                                <button style="
                                    border: none;
                                    margin-top: 10px;
                                    background: none;
                                    padding: 0;
                                    color: #0000de;
                                    text-decoration: underline;"
                                    onclick="window.vueApp.setZoomLevel(7, ${country.latitude}, ${country.longitude})">
                                    View ${country.name}
                                </button>`
                    }
                });
                
            },
            closeAllPopups() {
                const map = this.$refs.map.mapObject;
                map.eachLayer(layer => {
                    if (layer instanceof L.Marker && layer.isPopupOpen()) {
                        layer.closePopup();
                    }
                });
            },
            onZoomChange() {
                console.log("this.propertiesCountData")
                const map = this.$refs.map.mapObject;

                if (map) {
                    this.zoom = map.getZoom();
                    if (this.zoom <= 1 && this.zoom >= 0) {

                        this.markers = [];
                        this.setWorldMarkers();

                    } else if (this.zoom <= 3 && this.zoom >= 2) {

                        this.markers = [];
                        this.setContinentsMarkers();

                    } else if (this.zoom <= 6 && this.zoom >= 4) {

                        this.markers = [];
                        setTimeout(() => {

                            this.setContriesMarkers()

                        }, 100);
                    } else if (this.zoom >= 7) {
                        setTimeout(() => {

                            this.displayProperties()

                        }, 100);
                    }
                }
            },

            displayProperties() {
                this.markers = [];

                const geoCodeData = this.properties.content.map(
                    ({ content: property }) => {
                        if (property.media) {
                            property.media
                                .map((m) => {
                                    m.order = m.sortOrderMain || Number.MAX_VALUE;
                                    return m;
                                })
                                .sort((a, b) => a.order - b.order)
                                .slice(0, 4);
                        }

                        let ratingValue = property.googleReviews?.googleRating || 0;
                        let ratingCount =
                            property.googleReviews?.googleNumberOfReviews || 0;
                        ratingValue = parseFloat(ratingValue.toFixed(1));

                        return {
                            id: property.id,
                            name: property.name,
                            ...property.geoCode,
                            distributorType: property.distributorType,
                            ratingValue,
                            ratingCount,
                            address: property.addresses.length
                                ? property.addresses[0]
                                : null,
                            image: property.media.length
                                ? property.media[0].url
                                : "/images/property-default-image.jpg",
                        };
                    }
                );
                this.markers = geoCodeData.map(item => {
                    let propertyType;

                    if (item.distributorType === "A") {
                        propertyType = this.$t('listing.instantBookingRealTime');
                    } else if (item.distributorType === "B") {
                        propertyType = this.$t('listing.instantBooking');
                    } else {
                        propertyType = this.$t('listing.bookingOnRequest');
                    }

                    return {
                        position: [item.latitude, item.longitude],
                        popupText: `
                            <div class="image_section">
                                <a target="_blank" href="/listing/${item.id}">
                                    <img src="${item.image}" style="width: 200px; margin-bottom: 5px;" />
                                </a>
                            </div>
                            <div class="image_title">
                                <h5><a target="_blank" href="/listing/${item.id}">${item.name}</a></h5>
                                <div style="padding-bottom: 5px; margin-top: 10px;">${item.address}</div>
                                <div style="padding-bottom: 5px;">Property ID: ${item.id}</div>
                                <div style="padding-bottom: 10px;">Property Type: ${propertyType}</div>
                                <div style="display: flex; justify-content: space-between; align-items: center;">
                                    <div>${propertyType}</div>
                                    <div>
                                        <a target="_blank" href="/listing/${item.id}">
                                            <button style="padding: 5px 20px; color: white; background: #ff762b; border: none; border-radius: 20px; font-size: 14px;">
                                                Book Now
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>`
                    };
                });
            },
            async onMapMove() {

                if (this.isPopupOpen) {
                    return;
                }
                const map = this.$refs.map.mapObject;
                var bounds = map.getBounds();
                var radius = this.getRadiusFromBounds(bounds);
                var minProperties = 10; // Minimum properties needed to fill area
                try {

                    if (map.getZoom() >= 7) {
                        console.log("Fetching properties...");

                        let properties = await this.searchProperties({
                            latitude: map.getCenter().lat,
                            longitude: map.getCenter().lng,
                            radius: parseInt(radius),  // Use calculated radius
                            page: 1,
                            perPage: 200,
                        });

                        // If properties are less, increase radius and fetch again
                        if (properties.length < minProperties) {
                            console.log(`Properties too few (${properties.length}), increasing radius...`);
                            let newRadius = radius * 1.5; // Increase by 50%

                            properties = await this.searchProperties({
                                latitude: map.getCenter().lat,
                                longitude: map.getCenter().lng,
                                radius: parseInt(newRadius),
                                page: 1,
                                perPage: 200,
                            });
                        }

                        // Display properties only if we have enough
                        this.displayProperties(properties);
                    }
                } catch (error) {
                    console.error("Error fetching properties:", error);
                }
            },
            getRadiusFromBounds(bounds) {
                const { lat: north, lng: east } = bounds._northEast;
                const { lat: south, lng: west } = bounds._southWest;

                // Calculate center point
                const centerLat = (north + south) / 2;
                const centerLng = (east + west) / 2;

                // Distance from center to the northeast corner (approximate radius)
                return this.getDistance(centerLat, centerLng, north, east);
            },

            getDistance(lat1, lon1, lat2, lon2) {
                const R = 6371; // Radius of Earth in km
                const dLat = (lat2 - lat1) * (Math.PI / 180);
                const dLon = (lon2 - lon1) * (Math.PI / 180);

                const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
                    Math.sin(dLon / 2) * Math.sin(dLon / 2);

                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                return R * c; // Distance in km
            },



            async setZoomLevel(zoomLevel, lat, lng) {
                const map = this.$refs.map.mapObject;

                if (zoomLevel >= 7) {
                    await this.searchProperties({
                        latitude: lat,
                        longitude: lng,
                        radius: 200,
                        page: 0,
                        perPage: 1000,
                    });
                }
                if (!map) return;

                this.$nextTick(() => {
                    if (lat && lng) {
                        map.flyTo([lat, lng], zoomLevel, {
                            animate: true,
                            duration: 1, // Animation speed in seconds
                            easeLinearity: 0.25, // Smoothness of the animation
                        });
                    } else {
                        map.flyTo(map.getCenter(), zoomLevel, {
                            animate: true,
                            duration: 1,
                            easeLinearity: 0.25,
                        });
                    }
                });
            }

        }
    };
</script>

<style>
    /* Search Results Dropdown */
    .search-results {
        position: absolute;
        width: 100%;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        z-index: 1000;
        list-style: none;
        padding: 0;
        margin: 5px 0 0;
        max-height: 200px;
        overflow-y: auto;
    }

    .search-results li {
        padding: 8px 10px;
        cursor: pointer;
        border-bottom: 1px solid #ddd;
    }

    .search-results li:hover {
        background: #f0f0f0;
    }

    .search-results::-webkit-scrollbar {
        display: none;
    }

    .map-container {
        position: relative;
    }

    .search-field-container {
        position: absolute;
        top: 9px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9999;
    }

    .search-field {
        width: 300px;
    }

    .search-field-spinner {
        position: absolute;
        right: 5px;
        top: 8px;
        width: 25px;
        height: 25px;
        color: #ff762b;
    }
</style>